<template>
  <section class="hero_main" style="background-color: var(--bg-yellow);">
    <div class="container">
      <div class="row min-vh-100 min-vh-0 align-items-lg-center justify-content-center">
        <div class="col-lg-9 text-center hero-content pt-lg-5">
          <div class="main_img">
            <div>
              <img src="@/assets/img/fruggy_sideeyes.png" class="img-fluid" alt />
            </div>
          </div>
          <h1 class="text-body pt-4">Tu te poses des questions ? On tente d’y répondre ici.</h1>
        </div>
      </div>
    </div>

    <router-link to="/fruggr-pro" class="arrow_right_img nohover d-lg-flex d-none">
      <h5 class="font-weight-bold text-body mb-0 mr-4">Fruggr pro</h5>
      <div>
        <img src="@/assets/img/arrow.png" class="img-fluid slideleft" style="filter: invert();" alt />
      </div>
    </router-link>
    <router-link to="/product-team" class="arrow_left_img nohover d-lg-flex d-none">
      <div>
        <img
          src="@/assets/img/arrow.png"
          class="img-fluid slideright"
          style="filter: invert();"
          alt
        />
      </div>
      <h5 class="font-weight-bold text-body mb-0 ml-4">La team fruggr</h5>
    </router-link>

  </section>
</template>
<script>
export default {
  name: "IntroFaq",
};
</script>