<template>
  <section class="service_main" id="service">
    <div class="container position-relative">
      <div class="flex-column-reverse flex-lg-row justify-content-center row service_content">
        <div class="col-lg-4 text-center">
          <img src="@/assets/img/service-img-1.png" class="img-fluid" alt />
        </div>
        <div class="col-lg-6 text-lg-left text-center">
          <div class="btn_theme_num m-auto ml-md-0">1</div>
          <h3>Fais le bilan de tes impacts</h3>
          <p>
            Le score fruggr rassemble les impacts environnementaux, sociaux et la sobriété de nos usages smartphone.
            Moins nos usages ont un impact négatif, plus le score fruggr est élevé !
          </p>
        </div>
      </div>
      <div class="row service_content right">
        <div class="col-lg-6">
          <div class="btn_theme_num ml-md-auto m-auto">2</div>
          <h3>Challenge les autres</h3>
          <p>
            Compare ton score fruggr avec tes amis et challengez-vous pour faire monter votre score. Sur des challenges
            d’une à deux semaines, fais le point sur tes impacts. Découvre ton évolution et gagne des places dans le
            classement ! À découvrir bientôt.
          </p>
        </div>
        <div class="col-lg-4 text-center">
          <img src="@/assets/img/service-img-2.png" class="img-fluid" alt />
        </div>
      </div>
      <div class="flex-column-reverse flex-lg-row justify-content-center row service_content">
        <div class="col-lg-4 text-center">
          <img src="@/assets/img/service-img-3.png" class="img-fluid" alt />
        </div>
        <div class="col-lg-6 text-lg-left text-center">
          <div class="btn_theme_num m-auto ml-md-0">3</div>
          <h3>Améliore tes usages</h3>
          <p>
            Applique les tips (conseils) de l’application pour améliorer ton score fruggr et limiter tes impacts.
            Réseaux sociaux, usage des réseaux, entretien de son smartphone... Ces petits gestes combinés permettent de
            lutter contre la pollution numérique, à son échelle.
          </p>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center hero_main_footer">
        <a href="#purpose" class="slide_move js-scroll-trigger">
          <img src="@/assets/img/arrow-purple.png" class="img-fluid" alt />
        </a>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "MultiStep",
};
</script>
