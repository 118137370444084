<template>
  <div>
    <IntroFruggrPro />
    <FruggrProDetails />
    <DownloadSmall />
  </div>
</template>

<script>
import IntroFruggrPro from "@/components/blades/IntroFruggrPro.vue";
import DownloadSmall from "@/components/blades/DownloadSmall.vue";
import FruggrProDetails from "@/components/blades/FruggrProDetails.vue";

export default {
  name: "FruggrPro",
  components: { IntroFruggrPro, DownloadSmall, FruggrProDetails },
};
</script>
