<template>
  <section class="change_main py-100">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="row change_content align-items-center justify-content-center">
            <div class="col-lg-6">
              <h1>Prêt à changer ?</h1>
              <p>
                Démarre vite ton bilan Fruggr pour comprendre les impacts
                de tes usages et passer à l’action.
              </p>
              <div class="d-lg-flex d-block">
                <a href="https://play.google.com/store/apps/details?id=io.fruggr.yunr" target="_blank">
                  <img src="@/assets/img/play-store.png" class="img-fluid mr-lg-5" alt />
                </a>
                <a href="https://apps.apple.com/fr/app/fruggr/id1606924084" target="_blank">
                  <img src="@/assets/img/app-store.png" class="img-fluid my-lg-0 my-4" alt />
                </a>
              </div>
            </div>
            <div class="col-lg-4">
              <img
                src="@/assets/img/change-img.png"
                class="w-100 img-fluid d-lg-block d-none"
                alt
              />
              <img
                src="@/assets/img/mob-img.png"
                class="w-100 img-fluid d-block d-lg-none"
                alt
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "ReadyToChange",
};
</script>
