<template>
  <div>
    <img
      v-if="pictureExists(fruggitoPicture)"
      :src="resolve_img_url(fruggitoPicture)"
      class="img-fluid"
      alt
    />
    <a v-bind:href="link" v-bind:target="target" class="btn_theme">
      {{label}}
      <div v-if="arrow">
        <img src="@/assets/img/arrow.png" class="img-fluid" alt />
      </div>
    </a>
  </div>
</template>
<script>
export default {
  name: "Button",
  methods: {
    resolve_img_url: function (img_filename) {
      let images = require.context("@/assets/img", false, /\.png$|\.jpg$/);
      return images("./" + img_filename);
    },
    pictureExists(pic) {
      if (pic != null) return true;
      return false;
    },
  },
  props: {
    target: {
      type: String,
    },
    label: {
      type: String,
    },
    link: {
      type: String,
    },
    arrow: {
      type: Boolean,
    },
    fruggitoPicture: {
      type: String,
    },
  },
};
</script>