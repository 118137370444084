<template>
  <section class="change_main_download">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="row change_content align-items-center">
            <div class="col-lg-6">
              <h1>fruggr c’est aussi une application pour les entreprises.</h1>

              <div class="d-lg-flex d-block mt-5">
                <Button
                  label="Accéder à fruggr.io"
                  link="https://fruggr.io"
                  target="_blank"
                  :arrow="true"
                />
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_download_img">
      <img src="@/assets/img/footer_dowload_img.png" class="img-fluid" alt />
    </div>
  </section>
</template>
<script>
import Button from "@/components/Button.vue";

export default {
  name: "FruggrProTeaser",
  components: { Button },
};
</script>