<template>
  <div>
    <IntroFaq />
    <FaqContent />
    <DownloadSmall />
  </div>
</template>
<script>
import IntroFaq from "@/components/blades/IntroFaq.vue";
import FaqContent from "@/components/blades/FaqContent.vue";
import DownloadSmall from "@/components/blades/DownloadSmall.vue";

export default {
  name: "Faq",
  components: { IntroFaq, DownloadSmall, FaqContent },
};
</script>
