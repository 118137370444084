<template>
     <section>
        <div class="container">
            <div class="row justify-content-center ">
                <div class="col-lg-6">
                    <div class="steps_content">
                        <h1>La volonté de sensibiliser
                            le plus grand nombre</h1>

                        <p>Fruggr est né de la volonté de non seulement agir pour l'environnement mais également pour un
                            numérique plus responsable. Les impacts du numérique sont encore trop peu évoqués et
                            compris. Nous pouvons changer les choses à notre échelle et l’application Fruggr a souhait
                            d’y participer. </p>
                        <div class="d-lg-none d-block">
                            <img src="@/assets/img/mob_step_img.png" class="img-fluid w-100" alt="">
                        </div>
                    </div>
                    <div class="steps_content">
                        <h1>Le score fruggr est décomposé en 3 scores</h1>

                        <p class="pb-0"> <strong>Un score environnemental :</strong> qui prend en compte la fabrication
                            du téléphone
                            et son usage à travers la consommation data et le temps d’utilisation
                        </p>
                        <p class="py-3"><strong>Un score social :</strong> reposant sur le comportement de l’usager du
                            téléphone :
                            temps d’utilisation, achat responsable et reconditionné, usage d’applications</p>
                        <p class="pt-0"> <strong>Un score sobriété :</strong> reposant sur le comportement de l’usager
                            dans sa
                            recherche de sobriété : nombre d’applications installées/utilisées, usage du Wifi, pratique
                            de nettoyage…</p>
                    </div>
                    <div class="steps_content">
                        <h1>Notre partenaire</h1>
                        <div class="d-flex justify-content-center my-5">
                            <div>
                                <img src="@/assets/img/step-img-1.png" class="img-fluid mr-5" alt="">
                            </div>
                            <div>
                                <img src="@/assets/img/step-img-2.png" class="img-fluid ml-5" alt="">
                            </div>
                        </div>
                        <p class="py-0"><strong>L'application mobile fruggr grand public a été cofinancée par la <a href="">région Bretagne</a></strong>.
                        </p>
                        <p>Nous sommes un acteur de l'économie sociale et solidaire, agréée d'utilité sociale (ESUS) par le gouvernement.</p>
                    </div>
                    <div class="steps_content">
                        <h1>Design et développement</h1>
                        <div class="d-lg-none d-block text-center my-4">
                            <img src="@/assets/img/fruggy_calcul.png" class="img-fluid" alt="">
                        </div>
                        <p>Les équipes de design et de développement ont pris la main et se sont creusées les méninges
                            pour concevoir l’app fruggr. Créer une application inclusive et conçue pour limiter son
                            impact a été un challenge ! La première version de fruggr publiée et vos retours nous
                            permettent de nous améliorer.</p>
                    </div>


                </div>
                <div class="col-lg-7">
                    <div class="steps_content">
                        <h1>Échange avec d’autres experts du numérique responsable</h1>

                        <p>Vos retours comptent et ceux d’experts et personnes déjà sensibilisées aux impacts du
                            numérique aussi. Leurs yeux neufs sur notre projet favorise une amélioration constante.
                            C’est aussi pour cette raison que nous avons ouvert le code de notre algorithme de calcul.
                            L’open source permet à chacun de proposer, reformuler, d’améliorer et de faire avancer le
                            numérique responsable.</p>
                    </div>
                    <div class="steps_content">
                        <h1>Ce n'est qu'un début</h1>
  
                        <p>Il s'agit ici d'une première version qui nous l'espérons vous donnera envie d'agir avec nous pour un numérique plus durable. Nous prônons une démarche d'améliorons continue. Cette application s'enrichira pour vous engager dans des usages plus responsables. N'hésitez pas à nous partager vos retours et vos idées on se fera un plaisir d'échanger avec vous.</p>
                    </div>
                    <div class="steps_content service_content_none">
                        <h1>Hop ! L’app est dans vos mains</h1>


                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
  name: 'Ambtion',
  components: {
  }
}

</script>