<template>
  <div>
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<style>
@import "./assets/css/fruggr.css";
</style>

<script>
import Footer from "@/components/Footer.vue";
import Header from "./components/Header.vue";

export default {
  name: "App",
  components: {
    Footer,
    Header,
  },
};
</script>
