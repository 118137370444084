<template>
    <section class="download_main">
        <div class="container">
            <div class="row justify-content-between">
                <div class="col-lg-4 text-center download_content">
                    <h3>Android</h3>
                    <div>
                        <img src="@/assets/img/QRcode-android.png" class="img-fluid" alt="">
                    </div>
                    <a href="https://play.google.com/store/apps/details?id=io.fruggr.yunr">
                        <div class="mt-5">
                            <img src="@/assets/img/play-store.png" class="img-fluid" alt="">
                        </div>
                    </a>
                </div>
                <div class="col-lg-4 text-center download_content">
                    <h3>iOs</h3>
                    <div>
                        <img src="@/assets/img/QRcode-apple.png" class="img-fluid" alt="">
                    </div>
                    <a href="https://apps.apple.com/fr/app/fruggr/id1606924084">
                        <div class="mt-5">
                            <img src="@/assets/img/app-store.png" class="img-fluid" alt="">
                        </div>
                    </a>
                </div>
            </div>
            <div class="row pt-50 download_content justify-content-center">
                <div class="col-lg-6 pt-5 text-center">
                    <div>
                        <img src="@/assets/img/fruggy_eyesclosed.png" class="img-fluid" alt="">
                    </div>
                    <p class="mb-0">Si ton téléphone est trop vieux, félicitations !
                        Tu as donc amorti la plus grande part des impacts environnementaux. Si tu souhaites le changer
                        penses à passer par du reconditionné ou  par de la location sans option d'achat.

                    </p>
                    <h2>Merci d’agir avec nous</h2>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
  name: 'DownloadStore',
  components: {
  }
}

</script>