<template>
  <section class="hero_main" style="background-color: var(--bg-purple);">
    <div class="container">
      <div class="row min-vh-100 min-vh-0 align-items-lg-center justify-content-center">
        <div class="col-lg-10 text-center hero-content pt-lg-5 pb-3">
          <div class="main_img">
            <div>
              <img src="@/assets/img/fruggy_heart.png" class="img-fluid" alt />
            </div>
          </div>
          <h1 class="pt-4 pb-4">
            Télécharge Fruggr sur n'importe quel smartphone. Découvre ton score et
            améliore tes impacts.
          </h1>
        </div>
      </div>
    </div>
    <router-link to="/product-team" class="arrow_right_img nohover d-lg-flex d-none">
      <h5 class="font-weight-bold text-white mb-0 mr-4">La team fruggr</h5>
      <div>
        <img src="@/assets/img/arrow.png" style class="img-fluid slideleft" alt />
      </div>
    </router-link>
    <router-link to="/notre-demarche" class="arrow_left_img nohover d-lg-flex d-none">
      <div>
        <img src="@/assets/img/arrow.png" style class="img-fluid slideright" alt />
      </div>
      <h5 class="font-weight-bold text-white mb-0 ml-4">Notre démarche</h5>
    </router-link>
  </section>
</template>
<script>
export default {
  name: "IntroDownload",
};
</script>