<template>
    <section class="  change_main_step ">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-lg-10">
                    <div class="row change_content align-items-center justify-content-center">
                        <div class="col-lg-6">
                            <h1>Prêt à changer ?</h1>
                            <p>Démarre vite ton bilan Fruggr pour comprendre les impacts
                                de tes usages et passer à l’action.</p>
                            <div class="d-lg-flex d-block">
                                <a href="https://play.google.com/store/apps/details?id=io.fruggr.yunr">
                                    <img src="@/assets/img/play-store.png" class="img-fluid mr-lg-5 " alt="">
                                </a>
                                <a href="https://apps.apple.com/fr/app/fruggr/id1606924084">
                                    <img src="@/assets/img/app-store.png" class="img-fluid my-lg-0 my-4" alt="">
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-4 ">
                            <img src="@/assets/img/mob-img.png" class="w-100 img-fluid " alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
  name: "DownloadStore",
  components: {},
};
</script>