<template>
  <section>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="steps_content">
            <h1>La solution fruggr.io améliore l’empreinte des applications digitales des organisations.</h1>

            <div class="row justify-content-center">
              <div class="col-lg-8">
                <p
                  class="mb-0"
                >La plateforme de référence pour réduire son empreinte carbone numérique, rendre ses services accessibles à plus de monde, contribuer à une performance plus durable</p>
                <h2
                  class="row justify-content-center"
                ><strong>#1 mesurer</strong></h2>
                <h2
                  class="row justify-content-center"
                ><strong>#2 améliorer </strong></h2>
                <h2
                  class="row justify-content-center"
                ><strong>#3 communiquer</strong></h2>
              </div>
            </div>
          </div>
          <div class="steps_content service_content_none pb-0 pb-lg-5">
            <img
              src="@/assets/img/pro-img-1.png"
              class="img-fluid w-100"
              style="margin-bottom: -15px;"
              alt
            />
          </div>
        </div>
        <div class="col-lg-10 px-lg-5 py-100">
          <div class="steps_content service_content_none pt-0">
            <h1
              class="mb-5"
            >Venez découvrir comment fruggr améliore les impacts des services numériques.</h1>

            <Button label="Accéder à fruggr.io" link="https://fruggr.io" target="_blank" :arrow="true" />

          </div>
        </div>
        <div class="pro_img_footer">
          <img src="@/assets/img/pro-2.png" class="img-fluid" alt />
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import Button from "@/components/Button.vue";

export default {
  name: "FaqContent",
  components: { Button },
};
</script>