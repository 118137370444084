<template>
  <section>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="cgu_content service_content_none pb-0 pb-lg-5">
            <h1>Mentions Légales</h1>

            <p>Merci de lire avec attention les différentes modalités d’utilisation du présent site ou de son application avant d’y parcourir ses pages et ses contenus. En vous connectant sur ce site ou sur l'application mobile, vous acceptez sans réserves les présentes modalités. Aussi, conformément à l’article n°6 de la Loi n°2004-575 du 21 Juin 2004 pour la confiance dans l’économie numérique, les responsables du présent site internet fruggr.io et de son app mobile sont :</p>
            <p>
              <strong>Editeur du Site et de l'Application :</strong>Digital4Better
            </p>
            <p>
              <strong>SAS</strong> au capital de 120 000 €
            </p>
            <p>Entreprise de l'économie sociale et solidaire (ESS)</p>

            <p>
              <strong>Agrément ESUS</strong>, qualité d'entreprise solidaire d'utilité sociale par arrêté préfectoral
            </p>
            <p>
              Statut de
              <strong>société à mission</strong>
            </p>
            <p>Numéro de SIRET : 88419588400017</p>

            <p>
              <strong>Responsable éditorial</strong> : Frederick Marchand
            </p>
            <p>
              <strong>Email</strong> : hello@digital4better.com
            </p>
            <p>
              <strong>Site Web</strong> : digital4better.com
            </p>

            <h2 class="pt-50">Hébergement</h2>
            <p>
              <strong>Hébergeur</strong> : Google Firebase
            </p>
            <p>
              <strong>Site Web</strong> : firebase.com
            </p>

            <h2>Développement</h2>
            <p>
              <strong>Développeur du site</strong>: Digital4Better
            </p>

            <h2>Conditions d’utilisation</h2>
            <p>Ce site (app.fruggr.io) utilise différents langages web (HTML5, Javascript, CSS, etc…) pour un meilleur confort d'utilisation et un graphisme plus agréable. Nous vous recommandons d'utiliser une version à jour de votre navigateur (Firefox, Chrome, Safari, etc...). L'application mobile est développée via le framework flutter et a été imaginée pour fonctionner sur le plus grand nombre de smartphones.</p>
            <p>Digital4better met en œuvre tous les moyens dont elle dispose, pour assurer une information fiable et une mise à jour fiable de ses sites internet et de son application mobile. Toutefois, des erreurs ou omissions peuvent survenir. L'internaute devra donc s'assurer de l'exactitude des informations auprès de hello@digital4better.com, et signaler toutes modifications du site qu'il jugerait utile. Digital4better n'est en aucun cas responsable de l'utilisation faite de ces informations, et de tout préjudice direct ou indirect pouvant en découler.</p>

            <p>
              <strong>Cookies</strong>
            </p>
            <p></p>
            <p>Le site app.fruggr.io peut-être amené à vous demander l’acceptation des cookies pour des besoins de statistiques et d'affichage. Un cookies est une information déposée sur votre disque dur par le serveur du site que vous visitez. Il contient plusieurs données qui sont stockées sur votre ordinateur dans un simple fichier texte auquel un serveur accède pour lire et enregistrer des informations . Certaines parties de ce site ne peuvent être fonctionnelles sans l’acceptation de cookies.</p>
            <p>L'application mobile ne demande pas de cookies</p>
            <p>
              <strong>Liens hypertextes</strong>
            </p>
            <p></p>
            <p>Les sites internet de peuvent offrir des liens vers d’autres sites internet ou d’autres ressources disponibles sur Internet. Digital4Better ne dispose d'aucun moyen pour contrôler les sites en connexion avec ses sites internet. Digital4better ne répond pas de la disponibilité de tels sites et sources externes, ni ne la garantit. Elle ne peut être tenue pour responsable de tout dommage, de quelque nature que ce soit, résultant du contenu de ces sites ou sources externes, et notamment des informations, produits ou services qu’ils proposent, ou de tout usage qui peut être fait de ces éléments. Les risques liés à cette utilisation incombent pleinement à l'internaute, qui doit se conformer à leurs conditions d'utilisation.</p>
          </div>

          <h2>Services fournis</h2>
          <p>L'ensemble des activités de la société ainsi que ses informations sont présentés sur notre site digital4better.com. Digital4Better s’efforce de fournir des informations aussi précises que possible. les renseignements figurant sur le site digital4better.com ne sont pas exhaustifs et les photos non contractuelles. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne. Par ailleurs, tous les informations indiquées sur le site digital4better.com sont données à titre indicatif, et sont susceptibles de changer ou d’évoluer sans préavis.</p>

          <h2>Limitation contractuelles sur les données</h2>
          <p>Les informations contenues sur ce site et sur l'application mobile sont aussi précises que possible et le site et son application mobile remis à jour à différentes périodes de l’année, mais peut toutefois contenir des inexactitudes ou des omissions. Si vous constatez une lacune, erreur ou ce qui parait être un dysfonctionnement, merci de bien vouloir le signaler par email, à l’adresse hello@digital4better.com, en décrivant le problème de la manière la plus précise possible (page posant problème, type d’ordinateur et de navigateur utilisé,... ).</p>

          <h2>Propriété intellectuelle</h2>
          <p>Tout le contenu du présent sur le site app.fruggr.io et sur son application mobile , incluant, de façon non limitative, les graphismes, images, textes, vidéos, animations, sons, logos, gifs et icônes ainsi que leur mise en forme sont la propriété exclusive de la société à l'exception des marques, logos ou contenus appartenant à d'autres sociétés partenaires ou auteurs.</p>
          <p>Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces différents éléments est strictement interdite sans l'accord exprès par écrit de Digital4Better. Cette représentation ou reproduction, par quelque procédé que ce soit, constitue une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle. Le non-respect de cette interdiction constitue une contrefaçon pouvant engager la responsabilité civile et pénale du contrefacteur. En outre, les propriétaires des Contenus copiés pourraient intenter une action en justice à votre encontre.</p>

          <h2>Litiges</h2>
          <p>Les présentes conditions du site digital4better.com sont régies par les lois françaises et toute contestation ou litiges qui pourraient naître de l'interprétation ou de l'exécution de celles-ci seront de la compétence exclusive des tribunaux dont dépend le siège social de la société. La langue de référence, pour le règlement de contentieux éventuels, est le français.</p>

          <h2>Données personnelles</h2>
          <p>De manière générale, vous n’êtes pas tenu de nous communiquer vos données personnelles lorsque vous visitez notre site Internet app.fruggr.io ou lorsque vous utilisez notre application mobile.</p>
          <p>Cependant, ce principe comporte certaines exceptions. En effet, pour certains services proposés par notre site, vous pouvez être amenés à nous communiquer certaines données telles que : votre nom, votre fonction, le nom de votre société, votre adresse électronique, et votre numéro de téléphone. Tel est le cas lorsque vous remplissez le formulaire qui vous est proposé en ligne, dans la rubrique « contact ». Dans tous les cas, vous pouvez refuser de fournir vos données personnelles. Dans ce cas, vous ne pourrez pas utiliser les services du site ou de l'application mobile, notamment celui de solliciter des renseignements sur notre société, ou de recevoir les lettres d’information.</p>
          <p>Enfin, nous pouvons collecter de manière automatique certaines informations vous concernant lors d’une simple navigation sur notre site Internet, notamment : des informations concernant l’utilisation de notre site, comme les zones que vous visitez et les services auxquels vous accédez, votre adresse IP, le type de votre navigateur, vos temps d'accès. De telles informations sont utilisées exclusivement à des fins de statistiques internes, de manière à améliorer la qualité des services qui vous sont proposés. Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données.</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "LegalMentionContent",
  components: {},
};
</script>