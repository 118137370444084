<template>
  <section class="purpose_main">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="row">
            <div class="col-lg-7">
              <p class="mb-5">Une équipe bien plus large !</p>
              <h2>Fruggr c’est plus de 40 experts du numérique responsable. Entreprise à mission, nous avons à cœur de bouger les lignes d’un numérique plus durable.</h2>
            </div>
            <div class="col-lg-4 d-flex flex-column justify-content-between text-center">
              <div>
                <img src="@/assets/img/logo_d4b.svg" class="img-fluid my-5" alt />
              </div>

              <Button
                label="Site de Digital4Better"
                link="https://www.digital4better.com"
                target="_blank"
                :arrow="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Button from "@/components/Button.vue";

export default {
  name: "Digital4Better",
  components: { Button },
};
</script>
