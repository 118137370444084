import Vue from "vue";

export const store = Vue.observable({
    isBurgerMenuOpen: false
});

export const mutations = {
    toggleNav() {
        store.isBurgerMenuOpen = !store.isBurgerMenuOpen
        if(store.isBurgerMenuOpen) {
            document.body.style.overflowY = "hidden";
        } else {
            document.body.style.overflowY = "visible";
        }
    }
};