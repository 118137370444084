<template>
  <header>
    <div id="top-nav">
      <router-link to="/" class="nav-logo nohover">
        <img
          v-if="isFruggrLogoBlack"
          src="@/assets/img/logo_black.png"
          class="img-fluid"
          alt="logo fruggr"
        />
        <img v-else src="@/assets/img/logo.png" class="img-fluid" alt="logo fruggr" />
      </router-link>
      <div @click="toggle" id="burger-wrap" :class="{ wrap: isMenuOpen }">
        <a class="burger nohover" :class="{black: isFruggrLogoBlack }">
          <span></span>
        </a>
      </div>
      <div class="nav-menu" :class="{ open: isMenuOpen }">
        <div class="menu-list" :class="{ listopen: isMenuOpen }">
          <ul>
            <li>
              <router-link to="/">L’application fruggr mobile</router-link>
            </li>
            <li>
              <router-link to="/notre-demarche">Notre démarche</router-link>
            </li>
            <li>
              <router-link to="/telecharger">Télécharger l’application</router-link>
            </li>

            <li>
              <router-link to="/product-team">La Team fruggr</router-link>
            </li>
            <li>
              <router-link to="/faq">Questions/Réponses</router-link>
            </li>
            <li>
              <router-link to="/fruggr-pro">Fruggr pro</router-link>
            </li>
          </ul>
          <div class="d-lg-block d-none">
            <h4
              class="text-white font-weight-bold mb-0"
            >Tu n’as pas trouvé la bonne info ? Envoie-moi un message</h4>
            <img src="@/assets/img/nav_line.png" class="img-fluid" alt />
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <div class="d-lg-block d-none">
              <img src="@/assets/img/fruggy_sideeyes.png" class="img-fluid mr-5" alt />
            </div>

            <Button
              label="Nous contacter"
              link="mailto:great@digital4better.com"
              target="_blank"
              :arrow="true"
            />
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { store, mutations } from "@/store/index.js";
import Button from "@/components/Button.vue";

export default {
  name: "Header",
  components: { Button },

  computed: {
    isMenuOpen() {
      return store.isBurgerMenuOpen;
    },
    isFruggrLogoBlack() {
      if (this.isMenuOpen) {
        return false;
      }
      switch (this.$route.name) {
        case "Process":
          return true;
        case "Faq":
          return true;
        case "Cgu":
          return true;
        default:
          return false;
      }
    },
  },

  methods: {
    toggle: function () {
      mutations.toggleNav();
    },
  },
};
</script>
