<template>
  <div>
    <IntroProductTeam />
    <TeamMemberList />
    <Digital4Better />
    <DownloadSmall />
  </div>
</template>

<script>
import IntroProductTeam from "@/components/blades/IntroProductTeam.vue";
import DownloadSmall from "@/components/blades/DownloadSmall.vue";
import Digital4Better from "@/components/blades/Digital4Better.vue";
import TeamMemberList from "@/components/blades/TeamMemberList.vue";

export default {
  name: "ProductTeam",
  components: {
    IntroProductTeam,
    DownloadSmall,
    Digital4Better,
    TeamMemberList,
  },
};
</script>
