<template>
  <section>
    <div class="container-fluid">
      <div class="row justify-content-center team_main">
        <div class="col-lg-10">
          <div class="row justify-content-between">
            <TeamMember
              name="Alizée"
              job="UX  & Content Design"
              smartphone="iPhone 6s - score 74"
              description="Je suis plutôt sobre dans mes achats d'appareils électroniques (vive le reconditionné qui dure), mais je suis loin dans le classement pour mes usages 4G. C'est le point sur lequel je dois m'améliorer."
              avatar="team_1.png"
            />
            <TeamMember
              name="Celestin"
              job="Développeur"
              smartphone="FairPhone 3 - score 69"
              description="Après avoir maintenu mon OnePlus2 avec des réparations plus ou moins réussies, j'ai choisi le Fairphone pour faciliter celles-ci.

"
              avatar="team_2.png"
            />

            <TeamMember
              name="Van"
              job="UX  & Content Design"
              smartphone="Iphone 7 - score 54 (aïe lol)"
              description="Je suis dans le club du matériel reconditionné et du mode hors-connexion, mais j'ai encore le vilain réflexe de binge-watcher beaucoup de series en streaming... un point sur lequel je dois m'améliorer !"
              avatar="team_3.png"
            />
          </div>

          <div class="row justify-content-between">
            <TeamMember
              name="Franck"
              job="Brand Design"
              smartphone="8T - score 72"
              description="Je suis ce qu’on considère comme un total accro au smartphone et twitter addict. J’arrive cependant à garder mon mobile plus de 3 ans avant de le changer et j’utilise assez peu le réseau 4G."
              avatar="team_4.png"
            />
            <TeamMember
              name="Ronan"
              job="Impact Strategy"
              smartphone="S21 - score 57"
              description="Smartphone addict, je passe un nombre d'heures inconsidérables sur mon écran. Mes meilleurs tips sont choisir un smartphone reconditionné et être en permanence connecté à un réseau Wifi dès que j'en ai l'occasion"
              avatar="team_5.png"
            />
            <TeamMember
              name="Stefan"
              job="Architecture"
              smartphone="iPhone X - Score 59"
              description="Ma spécialité : garder le plus longtemps possible mes équipements : iphone, macbook... Même en tant que dev et technophile, on peut utiliser pendant de nombreuses années ses terminaux sans les changer"
              avatar="team_6.png"
            />
          </div>
        </div>
        <div class="col-lg-6 text-center">
          <h1 class="mb-5">Envie d’agir pour un numérique plus responsable ? Rejoignez-nous !</h1>
          <Button label="Envoyer votre candidature" link="https://digital4better.com/nous-rejoindre" target="_blank" :arrow="true" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TeamMember from "@/components/TeamMember.vue";
import Button from "@/components/Button.vue";

export default {
  name: "TeamMemberList",
  components: {
    TeamMember, Button
  },
};
</script>