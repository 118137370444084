<template>
   <div>
     <IntroProcess />
     <Ambition />
     <DownloadSmall />
   </div>
</template>
<script>
import Ambition from '../components/blades/Ambition.vue'
import DownloadSmall from '../components/blades/DownloadSmall.vue'
import IntroProcess from '../components/blades/IntroProcess.vue'


export default {
  name: 'Process',
  components: {
    Ambition, IntroProcess,
    DownloadSmall
  }
}

</script>
