<template>
  <section class="about_main" id="unique">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="justify-content-around row align-items-end">
            <div class="col-lg-5 about_content">
              <h1>Un indice unique !</h1>
              <p>
                Fruggr est né de la volonté de non seulement agir pour l'environnement mais également pour
                un numérique plus responsable.
              </p>
              <p>
                Depuis le 1er janvier 2022, les opérateurs télécom ont l'obligation d'afficher l'empreinte carbone des abonnés. Nous avons souhaité aller plus loin, avec un score plus poussé afin de mieux comprendre nos usages. Un scoring sur 100 pour une mesure d'empreinte environnementale, sociale et sobriété.
              </p>
              <p>
                Fruggr mobile permet à tout un chacun, à travers l’analyse du téléphone, des tests, conseils,... de mesurer et surtout d’agir sur ses impacts mobile.
              </p>
            </div>
            <div class="col-lg-5">
              <img src="@/assets/img/popup.png" class="w-100 img-fluid d-lg-block d-none" alt />
              <img src="@/assets/img/popup-mob.png" class="img-fluid d-lg-none d-block m-auto" alt />
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-center hero_main_footer">
          <a href="#service" class="slide_move js-scroll-trigger">
            <img src="@/assets/img/arrow-purple.png" class="img-fluid" alt />
          </a>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: "Score",
};
</script>
