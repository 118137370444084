import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ProductTeam from '../views/ProductTeam.vue'
import Download from '../views/Download.vue'
import Process from '../views/Process.vue'
import FruggrPro from '../views/FruggrPro.vue'
import Faq from '../views/Faq.vue'
import Cgu from '../views/Cgu.vue'

import { store, mutations } from '@/store/index.js'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/product-team',
    name: 'ProductTeam',
    component: ProductTeam
  },
  {
    path: '/telecharger',
    name: 'Download',
    component: Download
  },
  {
    path: '/notre-demarche',
    name: 'Process',
    component: Process
  },
  {
    path: '/fruggr-pro',
    name: 'FruggrPro',
    component: FruggrPro
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq
  },
  {
    path: '/cgu',
    name: 'Cgu',
    component: Cgu
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(store.isBurgerMenuOpen) {
    mutations.toggleNav();
  }
  next();
})

export default router
