<template>
      <section class="hero_main" style="background-color: var(--bg-pink);">
        <div class="container">
            <div class="row min-vh-100  min-vh-0 align-items-lg-center justify-content-center">
                <div class="col-lg-9 text-center hero-content pt-lg-5">
                    <div class="main_img">
                        <div>
                            <img src="@/assets/img/fruggy_heart.png" class="img-fluid" alt="">
                        </div>
                    </div>
                    <h1 class=" pt-4">La product team derrière fruggr</h1>
                    <h4 class="text-white font-weight-normal mt-5 px-lg-5 px-0">On espère que vous prendrez plaisir à
                        utiliser
                        fruggr
                        autant qu’on en a eu à
                        la concevoir. <br> Et on
                        a encore pleins d’idées pour la suite ! <br> Découvre l’équipe et leurs propres scores.</h4>

                </div>
            </div>
        </div>
        <router-link to="/faq" class="arrow_right_img nohover d-lg-flex d-none ">
            <h5 class="font-weight-bold text-white mb-0 mr-4"> F A Q</h5>
            <div>
                <img src="@/assets/img/arrow.png" class="img-fluid slideleft" alt="">
            </div>
        </router-link>
        <router-link to="/telecharger" class="arrow_left_img nohover d-lg-flex d-none">
            <div>
                <img src="@/assets/img/arrow.png" class="img-fluid slideright" alt="">
            </div>
            <h5 class="font-weight-bold text-white mb-0 ml-4">Télécharger l'app</h5>
        </router-link>
    </section>
</template>
<script>
export default {
  name: "IntroProductTeam",
};
</script>
