<template>
  <section class="hero_main" style="background-color: var(--bg-purple);">
    <div class="container">
      <div class="row min-vh-100 justify-content-center">
        <div class="col-lg-8 text-center hero-content">
          <div class="main_img">
            <img src="@/assets/img/mob-img.png" class="mob-img img-fluid" alt />
            <img src="@/assets/img/mob-img-line.png" class="mob-img-line img-fluid" alt />
          </div>
          <h1>
            Avec l’application fruggr,
            mesure et agis sur tes impacts mobile.
          </h1>
          <div class="d-lg-flex d-none align-items-center justify-content-center hero_main_footer">
            <div class="mr-3">
              <img src="@/assets/img/fruggy_sideeyes.png" class="img-fluid" alt />
            </div>
            <div class="d-flex">
              <h4 class="text-white font-weight-bold mr-4">Suis-moi, on t’en dit plus ci-dessous</h4>
              <a href="#unique" class="slide_move js-scroll-trigger">
                <img src="@/assets/img/arrow.png" class="img-fluid" alt />
              </a>
            </div>
          </div>
          <div class="d-lg-none d-block">
            <div class="mb-3 mt-4">
              <router-link to="/telecharger">
                <img src="@/assets/img/play-store.png" class="img-fluid" alt />
              </router-link>
            </div>
            <router-link to="/telecharger">
              <img src="@/assets/img/app-store.png" class="img-fluid" alt />
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <router-link to="/notre-demarche" class="arrow_right_img nohover d-lg-flex d-none">
      <h5 class="font-weight-bold text-white mb-0 mr-4">Notre démarche</h5>
      <div>
        <img src="@/assets/img/arrow.png" class="img-fluid slideleft" alt />
      </div>
    </router-link>
    
  </section>
</template>
<script>
export default {
  name: "Intro",
};
</script>