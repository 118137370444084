<template>
<div>
  <Intro />
  <Score />
  <MultiSteps/>
  <About />
  <ReadyToChange />
  
</div>
</template>

<script>
import ReadyToChange from '@/components/blades/ReadyToChange.vue'
import About from '@/components/blades/About.vue'
import Intro from '@/components/blades/Intro.vue'
import Score from '@/components/blades/Score.vue'
import MultiSteps from '@/components/blades/MultiSteps.vue'

export default {
  name: 'Home',
  components: {
    ReadyToChange, About, Intro, Score, MultiSteps
    
  }
}
</script>
