<template>
    <div>
      <IntroDownload/>
      <DownloadStore/>
      <FruggrProTeaser />
    </div>
</template>

<script>
import DownloadStore from '@/components/blades/DownloadStore.vue'
import FruggrProTeaser from '@/components/blades/FruggrProTeaser.vue'
import IntroDownload from '@/components/blades/IntroDownload.vue'

export default {
  name: 'Download',
  components: {
    DownloadStore, FruggrProTeaser, IntroDownload
  }
}

</script>