<template>
 
    <div class="col-lg-3 team_content text-center">
      <div>
        <img :src="resolve_img_url(avatar)" class="img-fluid" alt />
      </div>
      <h4>{{ name }}</h4>
      <h5>{{ job }}</h5>
      <span>{{ smartphone }}</span>
      <p>{{ description }}</p>
    </div>
</template>
<script>
export default {
  name: "TeamMember",
  components: {},
  methods: {
    resolve_img_url: function (avatar_img_filename) {
      let images = require.context("@/assets/img", false, /\.png$|\.jpg$/);
      return images("./" + avatar_img_filename);
    },
  },
  props: {
    name: {
      type: String,
    },
    job: {
      type: String,
    },
    smartphone: {
      type: String,
    },
    description: {
      type: String,
    },
    avatar: {
      type: String,
    },
  },
};
</script>