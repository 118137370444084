<template>
    <section class="purpose_main" id="purpose">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="row">
            <div class="col-lg-7">
              <h2 class="mb-5">À propos</h2>
              <h3>L'app mobile fruggr grand public est une initiative portée par 40 experts du numérique responsable. Nous sommes une entreprise à mission et agréée d'utilité sociale par l'état (ESUS pour les intimes), nous avons à cœur de bouger les lignes d’un numérique non seulement plus respectueux de l'environnement mais également plus accessible et inclusif </h3>
              <h3><a href="https://www.fruggr.io/" target="_blank" alt="Le site fruggr">Fruggr</a> c’est aussi une plateforme logicielle pour les professionnels car le changement passe aussi par les acteurs de l’économie.</h3>
              <p class="mt-5">
                A Nantes, à Rennes ou à Paris, passez nous dire un petit bonjour ou laissez-nous un message sur notre
                site de <a href="https://digital4better.com/contact" target="_blank">Digital4Better</a>.</p>
            </div>
            <div class="col-lg-4 d-lg-flex d-none flex-column  text-center">
              <div style="padding: 50px 0">
                <img src="@/assets/img/logo_d4b.svg" class="img-fluid" alt="">
              </div>
              <div style="padding: 50px 0">
                <Button label="Nous laisser un message" link="mailto:feedback.app@fruggr.io" target="_blank" :arrow="true" fruggitoPicture="fruggy_heart.png"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Button from "@/components/Button.vue";

export default {
  name: "About", 
  components: {
    Button
  }
};
</script>
