<template>
  <section>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="steps_content">
            <h1>
              Comment est calculé
              <br />le score fruggr ?
            </h1>

            <div class="row justify-content-center">
              <div class="col-lg-8">
                <p class="mb-0 pb-0">
                  <strong>Un score environnemental :</strong> qui prend en compte la fabrication du téléphone et son usage à travers la consommation data et le temps d’utilisation
                </p>
                <p class="mb-0 pb-0">
                  <strong>Un score social :</strong> reposant sur le comportement de l’usager du téléphone : temps d’utilisation, achat responsable et reconditionné, usage d’applications
                </p>
                <p class="mb-0 pb-0">
                  <strong>Un score sobriété :</strong> reposant sur le comportement de l’usager dans sa recherche de sobriété : nombre d’applications installées/utilisées, usage du Wifi, pratique de nettoyage…
                </p>
              </div>
            </div>
          </div>

          <div class="steps_content">
            <h1>Comment sont utilisées mes données collectées ?</h1>

            <div class="row justify-content-center">
              <div class="col-lg-8">
                <p
                  class="mb-0 pb-0"
                >Pour établir ton score Fruggr, nous avons besoin de connaître tes usages. Après ton autorisation, certaines informations sont collectées automatiquement sur ton téléphone (comme la marque de ton téléphone, ton utilisation du mode sombre...).</p>
                <p
                  class="mb-0 pb-0"
                >Ces données sont uniquement et strictement utilisées pour le bon fonctionnement de l’application mobile Fruggr. Nous ne les stockons pas et ne les utiliserons jamais à des fins commerciales.</p>
              </div>
            </div>
          </div>

          <div class="steps_content">
            <h1>Fruggr gagne-t-il de l’argent avec cette app mobile ?</h1>

            <div class="row justify-content-center">
              <div class="col-lg-8">
                <p
                  class="mb-0 pb-0"
                >L’application mobile fruggr est à but non-lucratif. Nous ne persevons aucun bénéfice financier au travers de celle-ci. Nous n’avons pas d’affiliation avec nos bons plans (présentant des entreprises luttant contre la pollution numérique). Notre projet est cofinancé grâce à la région Bretagne.</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-10 px-lg-5 pt-100">
          <div class="steps_content service_content_none pt-0">
            <h1 class="mb-5">Tu n’as pas trouvé réponse à ta question ? Contacte-nous.</h1>
            <Button
              label="Envoyer ta question"
              link="mailto:feedback.app@fruggr.io"
              target="_blank"
              :arrow="true"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Button from "@/components/Button.vue";

export default {
  name: "FaqContent",
  components: { Button },
};
</script>
