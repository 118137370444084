<template>
  <GguContent />
</template>
<script>
import GguContent from "../components/blades/GguContent.vue";

export default {
  name: "Cgu",
  components: {
    GguContent,
  },
};
</script>