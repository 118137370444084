<template>
    <section class="hero_main" style="background-color: var(--bg-blue);">
        <div class="container">
            <div class="row min-vh-100  min-vh-0 align-items-lg-center justify-content-center">
                <div class="col-lg-9 text-center hero-content pt-lg-5 pb-3">
                    <div class="main_img">
                        <div>
                            <img src="@/assets/img/fruggy_sideeyes.png" class="img-fluid" alt="">
                        </div>
                    </div>
                    <h1 class=" pt-4 pb-4">Fruggr c’est aussi une application pour les entreprises. </h1>
                    <h2 class="text-white font-weight-bold mt-lg-5 mb-lg-0 mb-5 mt-3"><a href="https://fruggr.io" target="_blank">fruggr.io</a></h2>


                </div>
            </div>
        </div>

       
        <router-link to="/" class="arrow_right_img nohover d-lg-flex d-none ">
            <h5 class="font-weight-bold text-white mb-0 mr-4">L'app fruggr</h5>
            <div>
                <img src="@/assets/img/arrow.png" class="img-fluid slideleft" alt="">
            </div>
        </router-link>
        <router-link to="/faq" class="arrow_left_img nohover d-lg-flex d-none">
            <div>
                <img src="@/assets/img/arrow.png" class="img-fluid slideright" alt="">
            </div>
            <h5 class="font-weight-bold text-white mb-0 ml-4">F A Q</h5>
        </router-link>
    

        <div class="pro_img_hero">
            <img src="@/assets/img/pro-1.png" class="img-fluid" alt="">
        </div>
    </section>
</template>
<script>
export default {
  name: "IntroFaq",
};
</script>